import Vue from 'vue'
import Router from 'vue-router'
import configAdmin from '@/config/admin'
import constants from '@/config/constants'
import configAction from '@/config/action'
import configEntity from '@/config/entity'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout.vue'

/**
 * ROUTES FORMAT :
 *
 * {
 *  redirect: '/'        Route redirect
 *  alias: '/'           Route alias
 *  name: 'router-name'  Name, used by list, mandatory
 *  component            Called Component
 *  children             Route children
 *  meta: {
 *   display: block      If `hidden:block` will show in the sidebar (default is 'none')
 *   name: 'name'        Name shown in menu and breadcrumb (recommended)
 *   class: 'className'  Icon classname, shown in the sidebar,
 *  },
 *   props: {
 *     isSidebarVisible: false, // hide left side menu, ex apps/lists, preferences
 *     isTopMenuAppContentVisible: false // hide app dropdown menu and webchat button, ex apps/lists, preferences, admins
 *   }
 * }
 **/

/**
 * DEFAULT ROUTES
 */
export const constantRouterMap = [
  {
    path: '/404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/signin/reset',
    name: 'reset',
    component: () => import('@/views/login/index.vue'),
    alias: '/:displayId/:assistantSlug/signin/reset'
  },
  {
    path: '/',
    redirect: '/signup'
  },
  {
    path: '/demo/:demoPageUrlCode/:platform?',
    name: 'demo',
    component: () => import('@/views/demo/index.vue')
  },
  {
    path: '/:displayId/:assistantSlug/dashboard',
    name: 'login_dashboard'
  },
  {
    path: '/:displayId/:assistantSlug/signin',
    name: 'login_redirect',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/:displayId/:assistantSlug/signup',
    name: 'signup_redirect',
    component: () => import('@/views/login/index.vue')
  }
]

/* eslint-disable id-length */
export default new Router({
  mode: 'history',
  // avoid page jumping during vue router transitions
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          x: 0,
          y: 0
        })
      }, 280)
    })
  },
  routes: constantRouterMap
})
/* eslint-enable id-length */

// Extra routes built once the user is logged in
export const asyncRouterMap = [
  {
    // For redirection vue-router.esm.js:16 [vue-router] missing param
    // for named route "Dashboard": Expected "displayId" to be defined
    path: '/:displayId/:assistantSlug/dashboard',
    component: Layout,
    meta: {
      name: 'dashboard',
      class: 'flaticon-line-graph',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN, configAdmin.ADMIN_ROLES.INSIGHTS_MANAGER]
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue')
      }
    ],
    alias: ['/dashboard/, /dashboard']
  },
  {
    path: '/:displayId/:assistantSlug/liveConversations',
    component: Layout,
    meta: {
      name: 'live_chat',
      class: 'flaticon-chat',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN, configAdmin.ADMIN_ROLES.LIVE_CHAT_AGENT],
      checkLiveChatCondition: true
    },
    children: [
      {
        path: '',
        name: 'live_chat',
        component: () => import('@/views/liveConversations/index.vue')
      }
    ]
  },
  {
    path: '/operations',
    redirect: '404',
    meta: {
      name: 'operations',
      class: 'fflaticon-more-v3',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    }
  },
  {
    path: '/:displayId/:assistantSlug/training',
    component: Layout,
    meta: {
      name: 'training',
      class: 'flaticon-download',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    props: true,
    children: [
      {
        path: '/',
        name: 'training',
        component: () => import('@/components/TrainingInbox/index.vue')
      }
    ]
  },
  {
    path: '/:displayId/:assistantSlug/conversations',
    component: Layout,
    meta: {
      name: 'conversations',
      class: 'flaticon-chat-1',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN, configAdmin.ADMIN_ROLES.INSIGHTS_MANAGER]
    },
    props: true,
    children: [
      {
        path: ':conversationId?',
        name: 'conversations_overview',
        props: {
          selectedTab: constants.CONVERSATION_TABS.CONVERSATIONS
        },
        component: () => import('@/views/conversations/index.vue')
      },
      {
        path: 'sms/dashboard',
        name: 'sms_dashboard',
        props: {
          selectedTab: constants.CONVERSATION_TABS.SMS
        },
        component: () => import('@/components/SmsDashboard/index.vue')
      }
    ]
  },
  {
    path: '/:displayId/:assistantSlug/users',
    component: Layout,
    meta: {
      name: 'users',
      class: 'flaticon-users',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN, configAdmin.ADMIN_ROLES.INSIGHTS_MANAGER]
    },
    props: true,
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/users/index.vue')
      },
      {
        path: ':userId',
        name: 'user',
        component: () => import('@/views/users/user.vue')
      }
    ]
  },
  {
    path: '/settings',
    redirect: '404',
    meta: {
      name: 'settings',
      class: 'fflaticon-more-v3',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    }
  },
  // Hiding the new UI for now
  // {
  //   path: '/:displayId/:assistantSlug/ai',
  //   component: Layout,
  //   props: true,
  //   meta: {
  //     name: 'conversational_ai',
  //     class: 'flaticon-interface-7',
  //     display: 'block',
  //     roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/conversationalAi/index.vue'),
  //       children: [
  //         {
  //           path: '',
  //           name: 'conversational_ai',
  //           redirect: 'prompt'
  //         },
  //         {
  //           path: 'prompt',
  //           name: 'conversational_ai_prompt',
  //           component: () => import('@/views/conversationalAi/components/Prompt/index.vue')
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '/:displayId/:assistantSlug/actions',
    component: Layout,
    props: true,
    meta: {
      name: 'assistant_actions',
      class: 'flaticon-network',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'assistant_actions',
        props: {
          selectedTab: constants.ACTION_TABS.ACTION_LIST
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'welcome',
        name: 'assistant_actions_welcome',
        props: {
          selectedTab: constants.ACTION_TABS.WELCOME_ACTION,
          actionSlug: configAction.ALWAYS_ACTIVATED_ACTIONS.WELCOME
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'fallback',
        name: 'assistant_actions_fallback',
        props: {
          selectedTab: constants.ACTION_TABS.FALLBACK_ACTION,
          actionSlug: configAction.ALWAYS_ACTIVATED_ACTIONS.FALLBACK
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'pushNotifications',
        name: 'assistant_actions_push_notifications',
        props: {
          selectedTab: constants.ACTION_TABS.PUSH_NOTIFICATIONS
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'pushNotifications/:notificationId',
        name: 'assistant_actions_push_notifications_settings',
        props: {
          selectedTab: constants.ACTION_TABS.PUSH_NOTIFICATIONS
        },
        component: () => import('@/views/actions/pushNotifications/components/pushNotification.vue')
      },
      {
        path: 'conditions',
        name: 'assistant_actions_conditions',
        props: {
          selectedTab: constants.ACTION_TABS.CONDITIONS
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'conditions/:conditionId',
        name: 'assistant_actions_condition_settings',
        props: {
          selectedTab: constants.ACTION_TABS.CONDITIONS
        },
        component: () => import('@/views/conditions/settings/index.vue')
      },
      {
        path: 'metadata',
        name: 'assistant_actions_metadata',
        props: {
          selectedTab: constants.ACTION_TABS.METADATA
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'metadata/:metadataId',
        name: 'assistant_actions_metadata_settings',
        props: {
          selectedTab: constants.ACTION_TABS.METADATA
        },
        component: () => import('@/views/metadata/settings/index.vue')
      },
      {
        path: 'slots',
        name: 'assistant_actions_slots',
        props: {
          selectedTab: constants.ACTION_TABS.SLOTS
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'slots/settings/:slotSlug?',
        props: {
          selectedTab: constants.ACTION_TABS.SLOTS
        },
        name: 'assistant_actions_slot_settings',
        component: () => import('@/components/SlotsManager/settings/index.vue')
      },
      {
        path: 'followup',
        name: 'assistant_actions_followup',
        props: {
          selectedTab: constants.ACTION_TABS.FOLLOWUP_ACTION
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'linkingSentences',
        name: 'assistant_actions_linking_sentences',
        props: {
          selectedTab: constants.ACTION_TABS.LINKING_SENTENCES
        },
        component: () => import('@/views/actions/index.vue')
      },
      {
        path: 'creation',
        component: () => import('@/views/actions/creation/index.vue'),
        children: [
          {
            path: '',
            redirect: 'step1'
          },
          {
            path: 'step1',
            name: 'assistant_actions_creation_step1',
            meta: {
              name: 'assistant_actions_creation'
            },
            component: () => import('@/views/actions/creation/About.vue')
          },
          {
            path: 'step2',
            name: 'assistant_actions_creation_step2',
            meta: {
              name: 'assistant_actions_creation'
            },
            component: () => import('@/views/actions/creation/Trigger.vue')
          },
          {
            path: 'step3',
            name: 'assistant_actions_creation_step3b',
            meta: {
              name: 'assistant_actions_creation'
            },
            component: () => import('@/views/actions/creation/AnswersSettings/index.vue')
          }
        ]
      },
      {
        path: ':actionId/:actionSlug?',
        name: 'assistant_actions_settings',
        meta: {
          name: 'assistant_actions_setup'
        },
        component: () => import('@/views/actions/settings/ActionSetup.vue')
      }
    ]
  },
  {
    path: '/:displayId/:assistantSlug/knowledge',
    component: Layout,
    meta: {
      name: 'assistant_knowledge',
      class: 'flaticon-interface-7',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'assistant_knowledge',
        component: () => import('@/views/knowledge/index.vue')
      },
      {
        path: 'settings/:knowledgeId/:knowledgeSlug',
        name: 'assistant_knowledge_settings',
        component: () => import('@/components/KnowledgeManager/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/:displayId/:assistantSlug/naturalLanguage',
    component: Layout,
    meta: {
      name: 'assistant_intents',
      class: 'flaticon-speech-bubble',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'assistant_intents',
        redirect: 'intents'
      },
      {
        path: 'intents',
        name: 'assistant_intents_settings',
        props: {
          selectedTab: constants.INTENT_TABS.intents
        },
        component: () => import('@/views/naturalLanguage/index.vue')
      },
      {
        path: 'intents/:intentSlug',
        name: 'assistant_intent_settings',
        component: () => import('@/views/intents/components/intentSettings.vue')
      },
      {
        path: 'entities',
        name: 'assistant_entities',
        props: {
          selectedTab: constants.INTENT_TABS.entities
        },
        component: () => import('@/views/naturalLanguage/index.vue')
      },
      {
        path: 'entities/:entityId',
        name: 'assistant_entity_settings',
        meta: {
          name: 'assistant_entities'
        },
        component: () => import('@/components/EntitySettings/index.vue')
      },
      {
        path: 'nlpWordRules',
        name: 'assistant_nlp_word_rules',
        props: {
          selectedTab: constants.INTENT_TABS.nlpWordRules
        },
        component: () => import('@/views/naturalLanguage/index.vue')
      },
      {
        path: 'nlpsettings',
        name: 'assistant_nlp_settings',
        props: {
          selectedTab: constants.INTENT_TABS.nlpsettings
        },
        component: () => import('@/views/naturalLanguage/index.vue')
      }
    ]
  },
  {
    path: '/deploy',
    redirect: '404',
    meta: {
      name: 'deploy',
      class: 'fflaticon-more-v3',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    }
  },
  {
    path: '/:displayId/:assistantSlug/platforms',
    component: Layout,
    meta: {
      name: 'platforms',
      class: 'flaticon-layers',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'assistant_platforms',
        component: () => import('@/views/platforms/index.vue')
      },
      {
        path: ':platformType',
        name: 'assistant_platforms_details',
        component: () => import('@/views/platforms/index.vue')
      }
    ]
  },
  {
    path: '/options',
    redirect: '404',
    meta: {
      name: 'options',
      class: 'fflaticon-more-v3',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    }
  },
  {
    path: '/:displayId/:assistantSlug/assistantSettings',
    component: Layout,
    props: true,
    meta: {
      name: 'assistant_settings',
      class: 'flaticon-interface-8',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        component: () => import('@/views/assistantSettings/index.vue'),
        children: [
          {
            path: '',
            name: 'assistant_settings',
            redirect: 'identity'
          },
          {
            path: 'identity',
            name: 'assistant_settings_identity',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/identity/index.vue')
          },
          {
            path: 'admins',
            name: 'assistant_settings_admins',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/admins/index.vue')
          },
          {
            path: 'modules',
            name: 'admin_modules',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/modules/index.vue')
          },
          {
            path: 'modules/:moduleId/:moduleSlug',
            name: 'admin_modules_settings',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/modules/settings/index.vue')
          },
          {
            path: 'localization',
            name: 'assistant_settings_localization',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/localization/index.vue')
          },
          {
            path: 'data_collection',
            name: 'assistant_settings_data_collection',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/dataCollection/index.vue')
          },
          {
            path: 'escalation',
            name: 'assistant_settings_escalation',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/escalation/index.vue')
          },
          {
            path: 'report',
            name: 'assistant_settings_report',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/report/index.vue')
          },
          {
            path: 'rating',
            name: 'assistant_settings_rating',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/rating/index.vue')
          },
          {
            path: 'export',
            name: 'assistant_settings_export',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/export/index.vue')
          },
          {
            path: 'live_chat',
            name: 'assistant_settings_live_chat',
            meta: {
              name: 'assistant_settings'
            },
            component: () => import('@/views/assistantSettings/components/liveChat/index.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin',
    redirect: '404',
    meta: {
      name: 'admin',
      class: 'fflaticon-more-v3',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    }
  },
  {
    path: '/assistant/creation',
    component: Layout,
    props: {
      isSidebarVisible: false,
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'assistant_creation',
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'assistant_creation_1',
        component: () => import('@/views/assistantCreation/onboarding/step1.vue')
      },
      {
        path: 'step2',
        name: 'assistant_creation_2',
        component: () => import('@/views/assistantCreation/onboarding/step2.vue')
      },
      {
        path: 'step3',
        name: 'assistant_creation_3',
        component: () => import('@/views/assistantCreation/onboarding/step3.vue')
      }
    ]
  },
  {
    path: '/apps/list',
    component: Layout,
    props: {
      isSidebarVisible: false,
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'apps_list',
      roles: configAdmin.ASSISTANT_SCOPED_ROLES
    },
    children: [
      {
        path: '',
        name: 'apps_list',
        component: () => import('@/views/appList/index.vue')
      }
    ]
  },
  // ADMIN SECTION
  //   - Every thing bellow, under /admin path, is super admin restricted
  //     configAdmin.ADMIN_ROLES.SUPER_ADMIN
  {
    path: '/admin/intentMasters',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_intent_masters',
      class: 'flaticon-speech-bubble-1',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_intent_masters',
        component: () => import('@/views/admin/intentMasters/index.vue')
      },
      {
        path: ':intentMasterId',
        name: 'admin_intent_masters_settings',
        meta: {
          name: 'admin_intent_masters'
        },
        component: () => import('@/views/admin/intentMasters/components/intentMasterSettings.vue')
      }
    ]
  },
  {
    path: configEntity.MASTER_PATH,
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_entities',
      class: 'flaticon-puzzle ',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_entities',
        component: () => import('@/components/EntitiesList/index.vue')
      },
      {
        path: ':entityId',
        name: 'admin_entity_settings',
        meta: {
          name: 'admin_entities'
        },
        component: () => import('@/components/EntitySettings/index.vue')
      }
    ]
  },
  {
    path: '/admin/actions',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_actions',
      class: 'flaticon-settings',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_actions',
        component: () => import('@/views/admin/actions/index.vue')
      },
      {
        path: 'settings/:actionSlug?',
        name: 'action_master_settings',
        component: () => import('@/views/admin/actions/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/admin/categories',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_categories',
      class: 'flaticon-settings',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_categories',
        component: () => import('@/views/admin/categories/index.vue')
      },
      {
        path: 'settings/:categoryId/:categorySlug',
        name: 'admin_categories_settings',
        component: () => import('@/views/admin/categories/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/admin/slots',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_slots',
      class: 'flaticon-list-2',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_slots',
        component: () => import('@/views/admin/slots/index.vue')
      },
      {
        path: 'settings/:slotSlug?',
        name: 'admin_slot_settings',
        component: () => import('@/components/SlotsManager/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/admin/training',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_training',
      class: 'flaticon-list-2',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_training',
        component: () => import('@/components/TrainingInbox/index.vue')
      }
    ]
  },
  {
    path: '/admin/assistants',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_assistants',
      class: 'flaticon-interface-7',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_assistants',
        component: () => import('@/views/admin/assistants/index.vue')
      },
      {
        path: 'settings/:assistantSlugAdmin?',
        name: 'admin_assistant_settings',
        component: () => import('@/views/admin/assistants/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/admin/features',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_features',
      class: 'flaticon-interface-7',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_features',
        component: () => import('@/views/admin/features/index.vue')
      },
      {
        path: ':featureId/:featureSlug',
        name: 'admin_features_settings',
        component: () => import('@/views/admin/features/settings/index.vue')
      }
    ]
  },
  {
    path: '/admin/industries',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_industries',
      class: 'flaticon-interface-7',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_industries',
        component: () => import('@/views/admin/industries/index.vue')
      },
      {
        path: 'settings/:industryId?',
        name: 'admin_industries_settings',
        component: () => import('@/views/admin/industries/settings/index.vue')
      }
    ]
  },
  {
    path: '/admin/admins',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      class: 'flaticon-user',
      name: 'admin_admins',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_admins',
        component: () => import('@/views/admin/admins/index.vue')
      },
      {
        path: 'settings/:adminId?',
        name: 'admin_admins_settings',
        component: () => import('@/views/admin/admins/settings/index.vue')
      }
    ]
  },
  {
    path: '/admin/translation',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      class: 'flaticon-interface-8',
      name: 'admin_translation',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        component: () => import('@/views/admin/translation/index.vue'),
        children: [
          {
            path: '',
            name: 'admin_translation',
            redirect: 'manual'
          },
          {
            path: 'manual',
            name: 'admin_translation_manual',
            meta: {
              name: 'admin_translation'
            },
            component: () => import('@/views/admin/translation/components/manual.vue')
          },
          {
            path: 'automatic',
            name: 'admin_translation_automatic',
            meta: {
              name: 'admin_translation'
            },
            component: () => import('@/views/admin/translation/components/automatic.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/admin/knowledge',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      class: 'flaticon-interface-8',
      name: 'admin_knowledge_base',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_knowledge_base',
        component: () => import('@/views/admin/knowledge/index.vue')
      },
      {
        path: 'settings/:knowledgeId/:knowledgeSlug',
        name: 'admin_knowledge_base_settings',
        component: () => import('@/components/KnowledgeManager/settings/index.vue'),
        meta: {
          display: 'none'
        }
      }
    ]
  },
  {
    path: '/admin/conditions',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      class: 'flaticon-interface-2',
      name: 'admin_conditions',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_conditions',
        component: () => import('@/views/conditions/index.vue')
      },
      {
        path: ':conditionId',
        name: 'admin_conditions_settings',
        component: () => import('@/views/conditions/settings/index.vue')
      }
    ]
  },
  {
    path: '/admin/phoneNumbers',
    component: Layout,
    props: {
      isTopMenuAppContentVisible: false
    },
    meta: {
      name: 'admin_phone_numbers',
      class: 'flaticon-support',
      display: 'block',
      roles: [configAdmin.ADMIN_ROLES.SUPER_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'admin_phone_numbers',
        component: () => import('@/views/admin/phoneNumbers/index.vue')
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    props: {
      isSidebarVisible: false,
      isTopMenuAppContentVisible: false
    },
    meta: {
      roles: configAdmin.ASSISTANT_SCOPED_ROLES
    },
    children: [
      {
        path: '',
        component: () => import('@/views/profile/index.vue'),
        children: [
          {
            path: '',
            redirect: 'account'
          },
          {
            path: 'region',
            name: 'profile_region',
            component: () => import('@/views/profile/components/region.vue')
          },
          {
            path: 'account',
            name: 'profile_account',
            component: () => import('@/views/profile/components/account.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/:displayId/:assistantSlug',
    component: Layout,
    meta: {
      roles: [configAdmin.ADMIN_ROLES.BASIC_ADMIN]
    },
    children: [
      {
        path: '',
        name: 'home',
        redirect: {
          name: 'dashboard'
        },
        component: () => import('@/views/dashboard/index.vue')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    redirect: '/404',
    hidden: true
  }
]
