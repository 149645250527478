const config = {
  TABS: {
    NLP_EXTRACTION: 'nlp_extraction',
    CUSTOM_EXTRACTION: 'custom_extraction'
  },
  NLP_EXTRACTIONS: {
    NONE: 'none',
    SYSTEM: 'system',
    EXAMPLE: 'example',
    ENUM: 'enum'
  },
  TAGIFY_REGEX: /\[\[([a-z_]+)\]\]/gm,
  CUSTOM_EXTRACTIONS: {
    NONE: 'none',
    REGEX: 'regex'
  },
  DEFAULT_NLP_SERVICE: 'dialogflow',
  NLP_SERVICES: {
    DIALOGFLOW: 'dialogflow'
  },
  OPENING_ENTITY_TAG: '[[',
  CLOSING_ENTITY_TAG: ']]',
  /**
   * ?= Positive lookbehind : Matches a group after the main expression without including it in the result.
   * ^  Matches the beginning of the string, or the beginning of a line if the multiline flag (
   * \\s matches single whitespace
   * [^A-Za-zÀ-ÖØ-öø-ÿ]' Match a single character not present in the alphabetic, accentuated characters list [^A-Za-zÀ-ÖØ-öø-ÿ]
   */
  START_WORD_REGEX: '^|\\s|[^A-Za-zÀ-ÖØ-öø-ÿ_]',
  /**
   * ?= Positive lookbehind : Matches a group after the main expression without including it in the result.
   * \\s matches single whitespace
   * ?=\\s matches following whitespace
   * $ Matches the end of the string, or the end of a line if the multiline flag (m) is enabled.
   * [^A-Za-zÀ-ÖØ-öø-ÿ]' Match a single character not present in the alphabetic, accentuated characters list [^A-Za-zÀ-ÖØ-öø-ÿ]
   */
  END_WORD_REGEX: '?=\\s|$|[^A-Za-zÀ-ÖØ-öø-ÿ_]',
  ENTITY_PREFIX: '@',
  NB_TOP_DICTIONARY_VALUES_ON_DROPDOWN: 3,
  NB_VALUES_IN_DROPDOWN: 100,
  MASTER_PATH: '/admin/entities',
  ASSISTANT_PATH: '/naturalLanguage/entities',
  SYSTEM_NUMBER_TYPE_ENTITIES: ['@sys.number', '@sys.cardinal', '@sys.ordinal', '@sys.number-integer'],
  SYSTEM_DATE_TYPE_ENTITIES: ['@sys.date', '@sys.date-time']
}

export default config
